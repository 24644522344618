/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article>*+* {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    background-color: #f7f8fc;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #424147;
}


/* Font */
@font-face {
    font-family: "Stolzl-Book";
    src: url("assets/Stolzl-Book.ttf")
}

@font-face {
    font-family: "TheSans-Plain";
    src: url("assets/TheSans-Plain-1.ttf")
}

/* Bootstrap */
:root {
    --bs-primary: black !important;
    --bs-primary-bg-subtle: black !important;
    --bs-link-color-rgb: black !important;
    --bs-link-hover-color: black !important;
    --bs-link-color: black !important;
    --bs-primary-border-subtle: black !important;
    --bs-focus-ring-color: black !important;
    --bs-link-hover-color-rgb: black !important;
    --bs-border-radius-lg: 5px !important;

}

html[lang="en"] body {
    font-family: 'Stolzl-Book', sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
}

html[lang="ar"] body {
    font-family: 'TheSans-Plain', sans-serif !important;
    font-size: 14px;
    line-height: 1.5;
    direction: rtl;
}

html[lang="ar"] ul {
    text-align: right;
}

html[lang="ar"] ul {
    direction: rtl;
}

html[lang="ar"] .sumbit-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}


textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
textarea:focus,
button:focus,
select:focus,
radio:focus,
.uneditable-input:focus {
    border-color: black !important;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(0, 0, 0, 0.5) !important;
    ;
}

input[type="radio"]:checked {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5) !important;
    border-color: black !important;
    background-color: black !important;
}


.min_form input:not([type="checkbox"]) {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    box-shadow: none !important;
}

.min_form select {
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: none;
}

.min_form textarea {
    border: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
}

.min_form button {
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.login_logo {
    width: 70%;
    display: block;
}

#login-container input[type="checkbox"] {
    background-color: #000;
}

.lang-sel {
    text-align: center;
}

.lang-sel a {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 24px;
}

.override_direction {
    direction: ltr !important;
}

* {
    padding-inline-start: 0;
}

.section_head {
    margin: 40px;
}


/* You can add these styles to your existing CSS or style tag */
.bg-success {
    background-color: #28a745;
    /* Green background color */
}

.bg-secondary {
    background-color: #6c757d;
    /* Grey background color */
}

/* You can adjust padding and margin as needed */
.p-2 {
    padding: 0.5rem;
}

.me-2 {
    margin-right: 0.5rem;
}

/* Cursor style for better UX on clickable elements */
.cursor-pointer {
    cursor: pointer;
}


/* image slider */
.image-slider {
    margin-top: 20px;
}

.slider-image {
    max-width: 100%;
    max-height: 200px;
    /* Adjust as needed */
    object-fit: contain;
    /* Preserve image aspect ratio */
}

.slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-image-container {
    position: relative;
}

.image-slider {
    margin-top: 20px;
    overflow: hidden;
    /* Prevent horizontal overflow */
}

.slider {
    position: relative;
}

.slick-dots {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 15px 0;
}

.slick-dots li {
    margin: 0 5px;
}

.slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.slick-dots li.slick-active button {
    background-color: #000000;
    /* Active dot color */
}

.slick-dots li button:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000;
    /* Black color */
    display: block;
    transition: background-color 0.3s ease;
}

.delete-button {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.slick-list {
    padding: 10px 0;
}


.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Black background with some transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it's on top of other content */
}

.loading-text {
    color: white;
    font-size: 24px;
    padding: 16px;
    background-color: black;
    border-radius: 8px;
}

.list-group-item.active {
    background-color: #000;
    border-color: #000;
    color: #fff;
}

.form-check-input:checked {
    background-color: #000;
    border-color: #000;
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell,
.rbc-agenda-event-cell {
    background-color: #ffffff !important;

}


.border-right {
    border-right: 1px solid #000 !important;
}

.border-left {
    border-left: 1px solid #000 !important;
}

.avatar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}

.upload-icon_avatar,
.delete-icon_avatar {
    color: white;
    font-size: 24px;
    margin: 0 10px;
    cursor: pointer;
}


/* Hover label for dropdown menu */
.hover-label:hover {
    cursor: pointer;
    background-color: rgba(173, 216, 230, 0.5);
}

.autocomplete-container {
    position: relative;
}

.autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 150px;
    width: 100%;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    z-index: 1;
}

.autocomplete-option {
    padding: 8px;
    cursor: pointer;
}

.autocomplete-option:hover {
    background-color: #f0f0f0;
}

/* Note bubble */
.note-bubble {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    max-width: 70%;
}

.note-content {
    flex-grow: 1;
}

.right-bubble {
    align-self: flex-end;
    background-color: #f0f0f0;
    color: #333;
}

.left-bubble {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: #333;
}

.note-metadata {
    display: flex;
    align-items: center;
}

.note-bubble-date {
    margin-left: 10px;
    font-size: 12px;
    color: #777;
}

.announcement-collapsed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.1s ease-in-out;
}

.announcement-expanded {
    max-height: 1000px;
    transition: max-height 0.5s ease-in-out;
}

.task-card {
    padding: 20px 0;
    /* box-shadow: 0px 0px 20px silver; */
    border: 1px solid #000;
    border-radius: 0;
    margin: 15px 0;
    background-color: white;
}

.devider-card {
    border: none;
}

.attachment-card,
.actions-card {
    padding: 20px 0;
    border: none !important;
    border-radius: 0;
    margin: 15px 0;
    background-color: white;
}

.comments {
    max-height: 50vh;
    overflow-y: auto;
}

.comments::-webkit-scrollbar {
    width: 5px;
}

.comments::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

.comments::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}


.comment {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding-left: 66px
}

.comment .comment-author-ava {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    border-radius: 50%;
    overflow: hidden
}

.comment .comment-author-ava>img {
    display: block;
    width: 100%
}

.comment .comment-body {
    position: relative;
    padding: 24px;
    border: 1px solid #e1e7ec;
    border-radius: 7px;
    background-color: #fff
}

.comment .comment-body::after,
.comment .comment-body::before {
    position: absolute;
    top: 12px;
    right: 100%;
    width: 0;
    height: 0;
    border: solid transparent;
    content: '';
    pointer-events: none
}

.comment .comment-body::after {
    border-width: 9px;
    border-color: transparent;
    border-right-color: #fff
}

.comment .comment-body::before {
    margin-top: -1px;
    border-width: 10px;
    border-color: transparent;
    border-right-color: #e1e7ec
}

.comment .comment-title {
    margin-bottom: 8px;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.comment .comment-text {
    margin-bottom: 12px
}

.comment .comment-footer {
    display: table;
    width: 100%
}

.comment .comment-footer>.column {
    display: table-cell;
    vertical-align: middle
}

.comment .comment-footer>.column:last-child {
    text-align: right
}

.comment .comment-meta {
    color: #9da9b9;
    font-size: 13px
}

.comment .reply-link {
    transition: color .3s;
    color: #606975;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .07em;
    text-transform: uppercase;
    text-decoration: none
}

.comment .reply-link>i {
    display: inline-block;
    margin-top: -3px;
    margin-right: 4px;
    vertical-align: middle
}

.comment .reply-link:hover {
    color: #0da9ef
}

.comment.comment-reply {
    margin-top: 30px;
    margin-bottom: 0
}

@media (max-width: 576px) {
    .comment {
        padding-left: 0
    }

    .comment .comment-author-ava {
        display: none
    }

    .comment .comment-body {
        padding: 15px
    }

    .comment .comment-body::before,
    .comment .comment-body::after {
        display: none
    }
}

@media (max-width: 992px) {
    .comments {
        max-height: fit-content;
    }
}